import { Icon } from "@ui/components/icon/icon";
import { useAnchorProps, UseAnchorProps } from "@uxf/core/hooks/useAnchorProps";
import { cx } from "@uxf/core/utils/cx";
import Link from "next/link";
import React, { AnchorHTMLAttributes, forwardRef, memo } from "react";

type LinkComponentProps = UseAnchorProps & AnchorHTMLAttributes<HTMLAnchorElement>;

const InfoTileLinkComponent = forwardRef<HTMLAnchorElement, LinkComponentProps>(
    ({ children, ...restAnchorProps }, ref) => {
        const anchorProps = useAnchorProps({
            className: "absolute inset-0 rounded-inherit outline-none",
            ...restAnchorProps,
        });
        return (
            <a ref={ref} {...anchorProps}>
                <span className="sr-only">{children}</span>
            </a>
        );
    },
);

InfoTileLinkComponent.displayName = "InfoTileLinkComponent";

interface Props {
    buttonProps: {
        onClick?: () => void;
        href?: string;
        title: string;
    };
    className?: string;
    description: string;
    isInsideSection?: boolean;
    route?: string;
    title: string;
}

export const InfoTile = memo<Props>(props => {
    const { buttonProps, className, description, isInsideSection, route, title } = props;
    const { onClick, title: buttonTitle, href } = buttonProps;
    const hrefUrl = href || route;
    const titleClassName = "sm:ui-typo-h5 mb-6 text-[1.625rem] font-bold";

    return (
        <div className={(onClick || hrefUrl) && "is-hoverable sm:hover-stripes sm:hover-stripes--tr show-cursor"}>
            <div
                className={cx(
                    "relative flex h-full flex-col justify-between bg-uxfViolet px-6 pb-8 pt-20 text-white xl:pt-28",
                    className,
                )}
            >
                <div className="absolute right-0 top-0 p-4">
                    <Icon name="arrowDiagonal" size={24} />
                </div>
                {isInsideSection ? (
                    <h3 className={titleClassName}>{title}</h3>
                ) : (
                    <h2 className={titleClassName}>{title}</h2>
                )}
                <p className="ui-typo-body opacity-70">{description}</p>
                <div className="ui-typo-body mt-6 flex items-center font-bold text-uxfOrange">
                    <p>{buttonTitle}</p>
                </div>
                {hrefUrl && (
                    <Link href={hrefUrl} passHref legacyBehavior>
                        <InfoTileLinkComponent onClick={onClick}>{buttonTitle}</InfoTileLinkComponent>
                    </Link>
                )}
            </div>
        </div>
    );
});

InfoTile.displayName = "InfoTile";
