import { InfoTile } from "@ui/components/info-tile/info-tile";
import React, { memo } from "react";
import { Trans, useTranslation } from "next-i18next";
import { useLocale } from "@translations/locale-context";
import { LandingPageHeroSection } from "@shared/components/landing-pages/landing-page-hero-section";
import { Button } from "@ui/components/button/button";

export const HeroSection = memo(() => {
    const { t } = useTranslation();
    const locale = useLocale();
    const isEn = locale === "en";

    return (
        <LandingPageHeroSection
            className="bg-uxfVioletLightest"
            button={
                <Button
                    variant="orange"
                    href={isEn ? "/en/book-consultation" : "/sjednat-konzultaci"}
                    className="sm:w-fit"
                >
                    {t("collaborate.button")}
                </Button>
            }
            title={
                <Trans
                    i18nKey="homepage.hero.title"
                    components={{
                        highlight: <span className="text-uxfOrange" />,
                    }}
                />
            }
            description={t("homepage.hero.subtitle")}
        >
            <div className="mt-16 grid grid-cols-1 gap-6 md:mt-28 lg:grid-cols-3 lg:gap-10">
                {t<string, { description: string; route: string; title: string }[]>("homepage.services.infoTiles", {
                    returnObjects: true,
                }).map(({ description, route, title }) => (
                    <InfoTile
                        buttonProps={
                            route
                                ? {
                                      title: t("homepage.services.infoTiles.buttonMoreInfoTitle"),
                                  }
                                : {
                                      href: isEn ? "/en/book-consultation" : "/sjednat-konzultaci",
                                      title: t("homepage.services.infoTiles.buttonTitle"),
                                  }
                        }
                        description={description}
                        key={title}
                        route={route}
                        title={title}
                    />
                ))}
            </div>
        </LandingPageHeroSection>
    );
});

HeroSection.displayName = "HeroSection";
