import React from "react";

export function ClutchTile() {
    return (
        <div className="mb-10 flex h-[200px] w-full items-center justify-center rounded-3xl rounded-bl-none bg-uxfViolet">
            <a href="https://clutch.co/profile/ux-fans#highlights" target="_blank" rel="noopener noreferrer">
                <img alt="clutch tile" src="/static/testimonials/logos/clutch.png" width={350} />
            </a>
        </div>
    );
}
