import { Page } from "@shared/pages/homepage";
import { GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    return {
        props: {
            ...(await serverSideTranslations(locale ?? "cs", ["common", "reference"])),
        },
    };
};

export default Page;
