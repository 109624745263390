import React, { memo, ReactElement } from "react";
import Image, { StaticImageData } from "next/image";
import { TextLink } from "@uxf/ui/text-link";
import Link from "next/link";
import { useTranslation } from "next-i18next";

export type LinkType = "project" | "caseStudy";

export interface TestimonialTileProps {
    author: string;
    authorImageUrl?: StaticImageData;
    authorPosition?: string;
    citation: string | ReactElement;
    isSingle?: boolean;
    logoUrl?: StaticImageData;
    logoHeight?: number;
    logoWidth?: number;
    linkUrl?: string;
    linkType?: LinkType;
}

export const TestimonialTile = memo<TestimonialTileProps>(props => {
    const { t } = useTranslation();
    return (
        <div className="break-inside-avoid rounded-3xl rounded-bl-none bg-white px-8 py-10 shadow-tile">
            {props.logoUrl && (
                <div className="mb-8 flex h-8 items-center">
                    <Image src={props.logoUrl} width={props.logoWidth} height={props.logoHeight} alt="" />
                </div>
            )}
            <figure>
                <blockquote className={`mb-4 ${props.isSingle ? "ui-typo-large" : "ui-typo-body"}`}>
                    {props.citation}
                </blockquote>
                <div className="ui-typo-body mb-4 text-uxfOrange underline">
                    {props.linkUrl && (
                        <Link href={props.linkUrl} passHref legacyBehavior>
                            <TextLink>
                                {props.linkType === "project" ? t("link.aboutProject") : t("link.caseStudy")}
                            </TextLink>
                        </Link>
                    )}
                </div>
                <div className="flex items-center">
                    {props.authorImageUrl && (
                        <div
                            className={`overflow-hidden rounded-full ${
                                props.isSingle ? "mr-6 size-14" : "mr-4 size-9"
                            }`}
                        >
                            <Image src={props.authorImageUrl} alt={props.author} />
                        </div>
                    )}
                    <figcaption>
                        <p className={props.isSingle ? "ui-typo-medium" : "ui-typo-body"}>{props.author}</p>
                        {props.authorPosition && (
                            <p className={`text-uxfMuted75 ${props.isSingle ? "ui-typo-small2" : "ui-typo-small3"}`}>
                                {props.authorPosition}
                            </p>
                        )}
                    </figcaption>
                </div>
            </figure>
        </div>
    );
});

TestimonialTile.displayName = "TestimonialTile";
