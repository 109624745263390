import { config } from "@config/config";
import { PROJECTS } from "@config/projects-config";
import { BlogCarouselSection } from "@shared/components/blog/blog-carousel-section";
import { CollaborateSection } from "@shared/components/collaborate-section/collaborate-section";
import { Helmet } from "@shared/components/helmet/helmet";
import { HeroSection } from "@shared/components/hero-section/hero-section";
import { NavBar } from "@shared/components/navbar/navbar";
import { ReferenceSection } from "@shared/components/reference-section/reference-section";
import { useLocale } from "@translations/locale-context";
import { Footer } from "@ui/components/footer/footer";
import { NextPage } from "next";
import React from "react";
import { useTranslation } from "next-i18next";
import { ServicesSection } from "@ui/components/services-section/services-section";
import { Testimonials } from "@ui/components/testimonials/testimonials";
import jesika from "@public/static/testimonials/people/jesika-liptakova.jpg";
import petr from "@public/static/testimonials/people/petr-kadlec.jpg";
import zlatka from "@public/static/testimonials/people/zlata-botero-vaskova.jpg";
import shopsyslogo from "@public/static/testimonials/logos/shopsys-logo.png";
import kalkulatorlogo from "@public/static/testimonials/logos/kalkulator-logo.png";
import sousedelogo from "@public/static/testimonials/logos/sousede-logo.png";

export const Page: NextPage = () => {
    const locale = useLocale();
    const { t } = useTranslation();

    return (
        <>
            <Helmet
                canonicalUrl={config.FRONTEND_URL + (locale === "en" ? "/en" : "/")}
                title={t("helmet.default.title")}
            />
            {/*<UkraineBanner />*/}
            <NavBar />
            <main>
                <HeroSection />
                {/*<CustomersSection />*/}
                <ReferenceSection
                    description={t("reference:section.text")}
                    name={t("reference:name")}
                    projects={PROJECTS}
                    title={t("reference:section.title")}
                />
                <Testimonials
                    sectionTitle={t("common:testimonials.title")}
                    sectionName={t("common:testimonials.subtitle")}
                    items={[
                        {
                            author: t("common:testimonials.kalkulator.author"),
                            authorPosition: t("common:testimonials.kalkulator.authorPosition"),
                            authorImageUrl: jesika,
                            citation: t("common:testimonials.kalkulator.text"),
                            logoUrl: kalkulatorlogo,
                            logoWidth: 193,
                            logoHeight: 23,
                            linkUrl:
                                locale === "en"
                                    ? "/en/blog/case-study/daktela-integration"
                                    : "/blog/pripadova-studie/integrace-daktela",
                            linkType: "caseStudy",
                        },
                        {
                            author: t("common:testimonials.shopsys.author"),
                            authorPosition: t("common:testimonials.shopsys.authorPosition"),
                            authorImageUrl: petr,
                            citation: t("common:testimonials.shopsys.text"),
                            logoUrl: shopsyslogo,
                            logoWidth: 170,
                            logoHeight: 36,
                            linkUrl:
                                locale === "en"
                                    ? "/en/reference/shopsys-commerce-cloud"
                                    : "/reference/shopsys-commerce-cloud",
                            linkType: "project",
                        },
                        {
                            author: t("common:testimonials.sousede.author"),
                            authorPosition: t("common:testimonials.sousede.authorPosition"),
                            authorImageUrl: zlatka,
                            citation: t("common:testimonials.sousede.text"),
                            logoUrl: sousedelogo,
                            logoWidth: 165,
                            logoHeight: 55,
                            linkUrl:
                                locale === "en"
                                    ? "/en/blog/case-study/sousede-rebuilding-running-project"
                                    : "/blog/pripadova-studie/sousede-prepsani-beziciho-projektu",
                            linkType: "caseStudy",
                        },
                    ]}
                />
                <ServicesSection
                    sectionName={t("homepage.servicesList.name")}
                    sectionTitle={t("homepage.servicesList.title")}
                    items={t<string, { description: string; title: string; route?: string }[]>(
                        "homepage.servicesList.items",
                        {
                            returnObjects: true,
                        },
                    )}
                    ctaTitle={t("homepage.servicesList.cta")}
                />
            </main>
            <BlogCarouselSection hasLayout />
            <CollaborateSection />
            <Footer />
        </>
    );
};
